/*
Template Name: Samply - Admin & Dashboard Template
Author: Pichforest
Version: 1.0.0
Website: https://Pichforest.com/
Contact: support@Pichforest.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins";

@import "custom/dark-layout/structure";
@import "custom/dark-layout/components";
@import "custom/dark-layout/plugins";
@import "custom/dark-layout/pages";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
//@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

.lcb {
  transition-timing-function: linear;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    transition-timing-function: linear;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    z-index: -2;
  }
  &:before {
    transition-timing-function: linear;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    transition: all 0s;
    border-radius: 0.25rem;
    z-index: -1;
  }
}

.lcb-active {
  transition-timing-function: linear;
  color: #fff;
  &:before {
    transition-timing-function: linear;
    transition: all 2s;
    width: 100%;
  }
}

@each $color, $value in $theme-colors {
  .lcb-#{$color} {
    &:before {
      background-color: darken($value, 15%);
    }
  }
}

.noTextOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.payment-method {
  padding: 20px;
  border-radius: 15px;

  &:hover {
    background: #0e1824;
  }
}

@media (max-width: 540px) {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    > * {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .page-content {
    padding: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mp-0 {
    padding: 0 !important;
  }

  .not-mobile {
    display: none;
  }
  .center-mobile {
    margin: 0 auto;
  }
}

.email_body blockquote {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid #039be5;
  font-family: "Roboto", serif;
  line-height: 1.5em;
  font-weight: 100;

  &:before,
  &:after {
    font-family: Calibri;
    color: #039be5;
    font-size: 34px;
  }
  &:before {
    content: "\201e";
  }
  &:after {
    content: "\201c";
  }
}

.rc-highlight {
  margin: -3px;
  padding: 3px;
  border-radius: 4px;
  background-color: #f8ffb4;
  transition: all 0.5s ease-in-out;
}